import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "underscore";
import { ppApiService } from "../../services/pp-api-service";
import Download from "./download";
import VideoContainer from "./video-container";
import swal from 'sweetalert';


interface State {
  incident: any;
  videos: any[];
  error: any;
  isProcessing: boolean;
  isToken: boolean;
}

class Incident extends React.Component<
  RouteComponentProps,
  State
> {
  incidentId: string;
  pollInterval = 4000;
  pollTimeout: any = 0;
  pollTokenTimeout: any = 0;
  pollSince = 0;
  token: string;

  constructor(props: RouteComponentProps) {
    super(props);
    this.incidentId = "";
    this.token = "";
    this.state = {
      incident: null,
      videos: [],
      error: null,
      isProcessing: false,
      isToken: false,
    };
  }

  componentDidMount() {
    if (this.props.location.pathname.startsWith('/incidents/a0/')) {
      this.token = (this.props.match.params as any).token;
      this.setState({ isToken: true });
      this.fetchIncidentByToken(this.token)
      this.saveRecentIncidetView("token", this.token)
      this.pollTokenTimeout = setInterval(() => {this.fetchIncidentByToken(this.token)}, this.pollInterval)
    }else{
      this.incidentId = (this.props.match.params as any).id;
      this.fetchIncident(this.incidentId)
      this.saveRecentIncidetView("id", this.incidentId)
      this.pollTimeout = setInterval(() => {this.fetchIncident(this.incidentId)}, this.pollInterval)
      this.setState({ isToken: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollTimeout);
    clearInterval(this.pollTokenTimeout);
  }

  render() {

    let { incident, videos, error, isProcessing, isToken } = this.state;

    if(error){
      return(
        <div className="column row text-center">
          <br />
          <div className="callout success" style={{position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <strong>{error.error}</strong>
          </div>
        </div>
      );
    }

    // if the incident data is not loaded yet, show a loading message
    if (!incident) {
      return (
        <div className="column row text-center">
          <br />
          <div className="callout success">
            <i className="fa fa-spinner fa-spin"></i>{" "}
            <strong>Fetching incident ...</strong>
          </div>
        </div>
      );
    }

    // Get rid of all characters from the teltag link
    var telTagPhone = incident.providerPhone.replace(/\D/g, "");
    var createdAt = moment(this.state.incident.createdAt).format("LLL");
    return (
      <div>
        <VideoContainer videos={videos} incident={incident} isToken={isToken}/>
        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-fw fa-bell"></i> Alerting Company Details
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <h3 style={{ marginTop: 0 }}>
                  <strong>{incident.providerName}</strong>
                </h3>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-fw fa-code"></i>&nbsp;{" "}
                    <strong>Incident Code:</strong> {incident.incidentCode}
                  </li>
                  <li>
                    <i className="fa fa-fw fa-hourglass-start"></i>&nbsp;{" "}
                    <strong>Created: </strong>
                    {createdAt}
                  </li>
                </ul>
              </div>
              <div className="col-xs-12 col-sm-4">
                {(isToken && incident.xvalue !== 0) ? (
                  (isProcessing || (incident.statusType === 2) || (incident.statusType === 3) || (incident.statusType === 4)) ? 
                  (<div className="d-flex flex-column align-items-start">
                    <button
                      type="button"
                      className="btn btn-success cancel-dispatch"
                      disabled={true}
                      onClick={this.Dispatch}
                    >
                      Dispatch
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger cancel-dispatch my-2"
                      disabled={true}
                      onClick={this.CancelDispatch}
                    >
                      Cancel Dispatch
                    </button>
                  </div>) : 
                  (<div className="d-flex flex-column align-items-start">
                  <button
                    type="button"
                    className="btn btn-success cancel-dispatch"
                    disabled={false}
                    onClick={this.Dispatch}
                  >
                    Dispatch
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger cancel-dispatch my-2"
                    disabled={false}
                    onClick={this.CancelDispatch}
                  >
                    Cancel Dispatch
                  </button>
                </div>)
                ): null}
              </div>
              <div className="col-xs-12 col-sm-4">
                <p>
                  <a href={"tel:" + telTagPhone} className="btn btn-primary">
                    <i className="fa fa-phone"></i> Call {incident.providerName}
                  </a>
                </p>
                <p>
                  Phone Number:{" "}
                  <a href={"tel:" + telTagPhone}>{incident.providerPhone}</a>
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-comments-o"></i> Comments
          </Card.Header>
          <Card.Body>
            {videos.map((v, i) => {
              if (v.comments) {
                return (
                  <div className="row" key={i}>
                    <div className="col-xs-12">
                      <p>
                        <strong>
                          {moment(v.createdAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                          :{" "}
                        </strong>
                        {v.comments}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
            <div className="row">
                    <div className="col-xs-12" style={{display: (this.state.incident && this.state.incident.OutcomeComment) ? 'block': 'none'}}>
                      <p>
                        <strong>
                          {moment(new Date(this.state.incident.OutcomeComment_updatedAt)).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                          :{" "}
                        </strong>
                        {this.state.incident.OutcomeComment}
                      </p>
                    </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{display: (this.state.incident && this.state.incident.GeocredsUserCount > 0) ? 'block': 'none'}}>
                  <p>
                    <strong>
                      {moment(new Date(this.state.incident.createdAt)).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                      :{" "}
                    </strong>
                    {this.state.incident.GeocredsUserCount} authorized users onsite
                  </p>
                </div>
            </div>
          </Card.Body>
        </Card>

        <Download incident={incident} videos={videos} />
      </div>
    );
  }

  saveRecentIncidetView(type: string, value: any) {
    var reqBody = {}
    if (type === "id") {
      reqBody = {
        reqType: "id",
        token: "",
        id: Number(value)
      }
    } else if (type === "token") {
      reqBody = {
        reqType: "token",
        token: value,
        id: 0
      }
    }
    ppApiService.saveRecentIncidentView(reqBody).then((data) => {
      console.log("data==>", data)
    }).catch(err => console.log(err))
  }

  fetchIncident(incidentID:string) {
    ppApiService
      .getIncident(incidentID)
      .then((incident) => {
        incident.regions = incident.regions.map((region: any) => {
          region.path = JSON.parse(region.path);
          return region;
        });

        this.fetchIncidentVideos();
        this.setState({
          incident: incident,
          error: null
        });
        // this.pollTimeout = setTimeout(
        //   this.fetchIncident(incidentID),
        //   this.pollInterval
        // );
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
      });
  }

  fetchIncidentVideos = () => {
    ppApiService
      .getIncidentVideos(this.incidentId, this.pollSince)
      .then((data) => {
        let videos = [...this.state.videos];

        data.videos.forEach((video: any, i: number) => {
          var foundIndex = _.findIndex(videos, { id: video.id });
          var existingVideo = videos[foundIndex];

          // if we already have the video, if its been disapproved then remove it
          if (existingVideo) {
            if (video.approved == false) {
              videos.splice(foundIndex, 1);
            } else {
              // videos[i] = video;
            }
          } else if (video.approved) {
            videos.push(video);
          }
        });

        // sort by created
        videos = _.chain(videos)
          .sortBy("createdAt")
          .sortBy("id")
          .value()
          .reverse();

        this.setState({
          videos: videos,
        });

        this.pollSince = data.since;
      })
      .catch((error) => {
        console.error("PPIncident:fetchIncidentVideos", error);
      });
  };

  fetchIncidentByToken(token:string) {
    ppApiService
      .getIncidentByToken(token)
      .then((incident) => {
        // if (incident.data && incident.data.longUrl) {
        //   location.href = incident.data.longUrl
        // }

        // Disabled Dispatch and Cancle Dispatch button
        this.setState({
          isProcessing: (incident.istimerdone === "True") ? true: false
        })
        incident.regions = incident.regions.map((region: any) => {
          region.path = JSON.parse(region.path);
          return region;
        });
        this.incidentId = incident.id.toString();
        this.fetchIncidentVideos();
        // this.setState({
        //   incident: incident,
        // });
        this.setState({
          incident: incident,
          error: null
        });
        // this.pollTokenTimeout = setTimeout(
        //   this.fetchIncidentByToken(token),
        //   this.pollInterval
        // );
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
      });
  }
  // cancel incident dispatch / 2 = closed / 1 = open
  CancelDispatch = () => {
    this.setState({
      isProcessing: true
    })

    var initialEl = document.createElement("input")
    initialEl.title = "Please enter your name and message to confirm"
    initialEl.id = "intitalid"
    initialEl.placeholder = "Please enter your name and message to confirm"
    initialEl.type = "text"
    initialEl.style.width = "100%"
    initialEl.style.padding = "10px";
    initialEl.style.fontSize = "16px";
    initialEl.style.border = "1px solid #ccc";
    initialEl.style.borderRadius = "5px";
    initialEl.style.boxShadow = "inset 0 1px 1px rgba(0, 0, 0, 0.075)";
    initialEl.style.outline = "none";
    initialEl.style.marginBottom = "10px";
    
    // warning alert
    swal({
      title: "Are you sure you want to cancel Police dispatch?",
      content:{
        element: initialEl,
      },
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    }).then(isConfirm => {
      if (isConfirm && this.incidentId) {
        // cancel dispatch payload
        var cancelReq = {
          incidentID: Number(this.incidentId),
          statusType: 2,
          url:location.href,
          initials:initialEl.value,
        }
        // endpoint for cancel dispatch
        ppApiService
          .cancelDispatch(cancelReq)
          .then((response) => {
            let incident = {...this.state.incident}
            incident.statusType = response.statusType
            if (response.updated) {
              this.setState({
                incident: incident,
              });
            }

            this.setState({
              isProcessing: false,
            })
          })
          .catch((error) => {
            this.setState({
              isProcessing: false,
            })

            this.setState({
              error: error,
            });
          });
      } else {
        this.setState({
          isProcessing: false,
        })
      }
    })
  }
  Dispatch = () => {
    this.setState({
      isProcessing: true
    })

    var initialEl = document.createElement("input")
    initialEl.title = "Please enter your name and message to confirm"
    initialEl.id = "intitalid"
    initialEl.placeholder = "Please enter your name and message to confirm"
    initialEl.type = "text"
    initialEl.style.width = "100%"
    initialEl.style.padding = "10px";
    initialEl.style.fontSize = "16px";
    initialEl.style.border = "1px solid #ccc";
    initialEl.style.borderRadius = "5px";
    initialEl.style.boxShadow = "inset 0 1px 1px rgba(0, 0, 0, 0.075)";
    initialEl.style.outline = "none";
    initialEl.style.marginBottom = "10px";

    // warning alert
    swal({
      title: "Are you sure you want to Police dispatch?",
      content:{
        element: initialEl,
      },
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    }).then(isConfirm => {
      if (isConfirm && this.incidentId) {
        // cancel dispatch payload
        var cancelReq = {
          incidentID: Number(this.incidentId),
          statusType: 4,
          url:location.href,
          initials:initialEl.value,
        }
        // endpoint for cancel dispatch
        ppApiService
          .cancelDispatch(cancelReq)
          .then((response) => {
            let incident = {...this.state.incident}
            incident.statusType = response.statusType
            if (response.updated) {
              this.setState({
                incident: incident,
              });
            }

            this.setState({
              isProcessing: false,
            })
          })
          .catch((error) => {
            this.setState({
              isProcessing: false,
            })

            this.setState({
              error: {error: error.error.Message},
            });
          });
      } else {
        this.setState({
          isProcessing: false,
        })
      }
    })
  }
}

export default withRouter(Incident)